
import axios from 'axios'

const generalInstance = axios.create({
    baseURL: process.env.REACT_APP_ASSET_BASE_URL,
});

const salesInstance = axios.create({
    baseURL: process.env.REACT_APP_ASSET_BASE_URL + '/SalesAnalysis',
})

const billDataInstance = axios.create({
    baseURL: process.env.REACT_APP_ASSET_BASE_URL + '/BillData',
})

const bulkProposalInstance = axios.create({
    baseURL: process.env.REACT_APP_ASSET_BASE_URL + '/BulkProposal',
})

const bebbiaInstance = axios.create({
    baseURL: process.env.REACT_APP_ASSET_BASE_URL + '/Bebbia',
})

const inventoryInstance = axios.create({
    baseURL: process.env.REACT_APP_ASSET_BASE_URL + '/Inventory',
})

const propertyInstance = axios.create({
    baseURL: process.env.REACT_APP_ASSET_BASE_URL + '/Property',
})

const installTrackingInstance = axios.create({
    baseURL: process.env.REACT_APP_ASSET_BASE_URL + '/InstallTracking',
})

const commsDownInstance = axios.create({
    baseURL: process.env.REACT_APP_ASSET_BASE_URL + '/CommsDownReport',
})

export default {
    getEmailAndToken: (id) =>
        generalInstance({
            'method': 'GET',
            'url': '/BypassLogin/ConservationReportLogin',
            'params': {
                'id': id,
            },
        }),
    getAccounts: (urlEnding, token) =>
        generalInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/AssetDashboard/' + urlEnding,
    }),
    getData: (startDate, endDate, accountId, urlEnding, token) =>
        generalInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': urlEnding,
            'params': {
                'queryStart': startDate,
                'queryEnd': endDate,
                'accountId': accountId,
            },
        }),
    getTableData: (startDate, endDate, accountId, skip, urlEnding, token) =>
        generalInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/AssetDashboard/' + urlEnding,
            'params': {
                'queryStart': startDate,
                'queryEnd': endDate,
                'skip': skip,
                'accountId': accountId,
            },
        }),
    getDataPerProperty: (propertyId, startDate, endDate, accountId, urlEnding, token) =>
        generalInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': urlEnding,
            'params': {
                'propertyId': propertyId,
                'queryStart': startDate,
                'queryEnd': endDate,
                'accountId': accountId,
            },
        }),
    getAlarmEmailBody: (propId, alarmConfigId, accountId, urlEnding, token) =>
        generalInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/AssetDashboard/' + urlEnding,
            'params': {
                'propId': propId,
                'alarmConfigId': alarmConfigId,
                'accountId': accountId,
            },
        }),
    getPhotos: (propName, urlEnding, token) =>
        generalInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/AssetDashboard/' + urlEnding,
            'params': {
                'propertyName': propName
            },
        }),
    postData: (firstValue, secondValue, thirdValue, email, urlEnding, token) =>
        generalInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/AssetDashboard/' + urlEnding,
            'params': {
                'firstValue': firstValue,
                'secondValue': secondValue,
                'thirdValue': thirdValue,
                'email': email
            }
        }),
    getGeneric: (token, url) => {
        return generalInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': url
        });
    },
    getGenericSalesAnalysis: (token, url) => {
        return salesInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': url
        });
    },
    getRatesPerUtility: (token, utilityId) => {
        return salesInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/RatesPerUtility',
            'params': {
                'utilityId': utilityId
            }
        });
    },
    getProposalDraft: (token, proposalId) => {
        return salesInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/GetPropertyInfoDraft',
            'params': {
                'proposalId': proposalId
            }
        });
    },
    saveProposalDraft: (token, proposal) => {
        return salesInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/SavePropertyInfoDraft',
            'data': proposal
        });
    },
    saveBillInputDraft: (token, proposalBillInfo) => {
        return salesInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/SetBillInfo',
            'data': proposalBillInfo
        });
    },
    getProposals: (token, status) => {
        return salesInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/GetProposals',
            'params': {
                'status': status
            }
        });
    },
    updateStatus: (token, proposalId, status) => {
        return salesInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/UpdateStatus',
            'params': {
                'proposalId': proposalId,
                'status': status
            }
        });
    },
    getBillInput: (token, proposalId) => {
        return salesInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/GetBillInfo',
            'params': {
                'proposalId': proposalId
            }
        });
    },
    verifyBillData: (token, proposalBillInfo) => {
        return salesInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/VerifyBillCost',
            'data': proposalBillInfo
        });
    },
    getUtilityMeters: (token, propertyName, accountName) => {
        return salesInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/GetUtilityMeters',
            'data': {
                'PropertyName': propertyName,
                'AccountName': accountName
            }
        });
    },
    getProposal: (token, proposal) => {
        return salesInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/GenerateProposal',
            'data': proposal
        });
    },
    saveProposal: (token, proposal) => {
        return salesInstance({
           'method': 'POST',
           'headers': {
            'Authorization': 'Bearer ' + token,
           },
           'url': '/SaveProposal',
           'data': proposal
        });
    },
    getSquareFootage: (token, address) => {
        return salesInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/GetSquareFootage',
            'params': {
                'address': address
            }
        })
    },
    getMeterEstimate: (token, irrigatedArea) => {
        return salesInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/GetMeterEstimate',
            'params': {
                'irrigatedArea': irrigatedArea
            }
        });
    },
    getControllerEstimate: (token, irrigatedArea) => {
        return salesInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/GetControllerEstimate',
            'params': {
                'irrigatedArea': irrigatedArea
            }
        });
    },
    getZoneEstimate: (token, irrigatedArea) => {
        return salesInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/GetZoneEstimate',
            'params': {
                'irrigatedArea': irrigatedArea
            }
        });
    },
    sendPDFsToBackend: (token, files, propertyId) => {
        return billDataInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/SaveFiles',
            'data': files,
            'params': {
                'propertyId': propertyId
            }
        });
    },
    uploadPDFBills: (token, files, utilityRateId, proposalId) => {
        return billDataInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/UploadPDFBills',
            'data': files,
            'params': {
                'utilityRateId': utilityRateId,
                'proposalId': proposalId
            }
        });
    },
    energyCapUpload: (token, files) => {
        return bulkProposalInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/EnergyCapParse',
            'data': files
        });
    },
    conserviceUpload: (token, files) => {
        return bulkProposalInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/ConserviceParse',
            'data': files
        });
    },
    simpleBillsUpload: (token, files) => {
        return bulkProposalInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/SimpleBillsParse',
            'data': files
        });
    },
    waterCompassUpload: (token, files) => {
        return bulkProposalInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/WaterCompassParse',
            'data': files
        });
    },
    schneiderUpload: (token, files) => {
        return bulkProposalInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/SchneiderParse',
            'data': files
        });
    },
    updateBulkProposalInputs: (token, proposal) => {
        return bulkProposalInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/UpdateBulkProposalInputs',
            'data': proposal
        });
    },
    getBebbiaDevicesList: (token, accountId) => {
        return bebbiaInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/DevicesList',
            'params': {
                'accountId': accountId
            }
        })
    },
    getBebbiaDailyWaterConsumptions: (token, data) => {
        return bebbiaInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/DeviceDailyWaterConsumptions',
            'data': data
        })
    },
    getBebbiaDailyTDSAverages: (token, data) => {
        return bebbiaInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/DeviceDailyQualityOfWater',
            'data': data
        })
    },
    getBebbiaDailyFlowRates: (token, data) => {
        return bebbiaInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/DeviceDailyFlowRates',
            'data': data
        })
    },
    getBebbiaTDSChartData: (token, data) => {
        return bebbiaInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/TDSChartData',
            'data': data
        })
    },
    getBebbiaCartridgeChartData: (token, data) => {
        return bebbiaInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/RemainingLifetimeChartDate',
            'data': data
        })
    },
    getBebbiaPortfolioTableData: (token, data) => {
        return bebbiaInstance({
            'method': 'POST',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/PortfolioTable',
            'data': data
        })
    },
    getBebbiaClientsTableData: (token) => {
        return bebbiaInstance({
            'method': 'GET',
            'headers': {
                'Authorization': 'Bearer ' + token,
            },
            'url': '/ClientsTable'
        })
    },
    getBebbiaPurificationTableData: (token, options) => {
        return bebbiaInstance({
            'method': 'POST',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/PurificationTableData',
            'data': options
        })
    },
    getHardwareItems: (token) => {
        return inventoryInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/AssembliesAndComponents'
        })
    },
    updateInventoryCount: (token, data) => {
        return inventoryInstance({
            'method': 'POST',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/SaveInventory',
            'data': data
        })
    },
    getInventoryCounts: (token) => {
        return inventoryInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/InventoryCount'
        })
    },
    updateAssemblies: (token, data) => {
        return inventoryInstance({
            'method': 'POST',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Assemblies',
            'data': data
        })
    },
    getAssemblies: (token) => {
        return inventoryInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Assemblies'
        })
    },
    getOrders: (token) => {
        return inventoryInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Orders'
        })
    },
    updateOrders: (token, data) => {
        return inventoryInstance({
            'method': 'POST',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Order',
            'data': data
        })
    },
    getProperties: (token) => {
        return propertyInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Properties'
        })
    },
    getPropertyControllers: (token, propertyId) => {
        return propertyInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Controllers',
            'params': {
                'propertyId': propertyId
            }
        })
    },
    getPropertyWaterSources: (token, propertyId) => {
        return propertyInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/WaterSources',
            'params': {
                'propertyId': propertyId
            }
        })
    },
    orderImport: (token, file) => {
        return inventoryInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Import',
            'data': file
        });
    },
    getYahooData: (token) => {
        return generalInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/EvapCooling/YahooData'
        })
    },
    getComponentsByController: (token, controllerId) => {
        return inventoryInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/ComponentsByController',
            'params': {
                'controllerId': controllerId
            }
        })
    },
    getComponentsByWaterSource: (token, waterSourceId) => {
        return inventoryInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/ComponentsByWaterSource',
            'params': {
                'waterSourceId': waterSourceId
            }
        })
    },
    returnItem: (token, inventoryItemId) => {
        return inventoryInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Return',
            'params': {
                'inventoryItemId': inventoryItemId
            }
        });
    },
    getInventoryInLimbo: (token) => {
        return inventoryInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Limbo'
        })
    },
    refurbishItem: (token, data) => {
        return inventoryInstance({
            'method': 'POST',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Refurbish',
            'data': data
        })
    },
    indoorProposal: (token, payload) => {
        return salesInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/IndoorProposal',
            'params': payload
        })
    },
    getInstallTrackingStatus: (token) => {
        return installTrackingInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Current'
        })
    },
    updateInstallStatus: (token) => {
        return installTrackingInstance({
            'method': 'POST',
            'headers': {
                'Accept': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Updated'
        })
    },
    getCommsDownReport: (token) => {
        return commsDownInstance({
            'method': 'GET',
            'headers':{
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Current'
        })
    },
    updateCommsDownReport: (token, payload) => {
        return commsDownInstance({
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            'url': '/Updated',
            'data': payload
        })
    },
}